import 'bootstrap/dist/js/bootstrap';

import { createApp } from 'vue';

import '@/assets/styles/main.scss'

import App from './App';
import { store } from './store'
import router from './router';

import './plugins/validate';
import baseComponents from './plugins/baseComponents';
import modal from './plugins/modal';

import VueFilters from '@/util/vue.filters';

const VueApp = window.VueApp = createApp(App);
VueApp.use(store);
VueApp.use(router);
VueApp.use(modal);
for (const name in baseComponents) {
  VueApp.component(name, baseComponents[name]);
}
VueApp.mount('#app');

// Vue.config.productionTip = false;
VueApp.config.globalProperties.$filters = VueFilters;

if (process.env.NODE_ENV === 'production') {
  require('./assets/scripts/metrika');
  require('./assets/scripts/google-metrika')
  require('./assets/scripts/top-mail-ru')

  /*Sentry.init({
      dsn: 'https://a15f17c75b684510ad0f55c1a0535656@sentry.io/3717554',
      integrations: [new Integrations.Vue({Vue, attachProps: true})],
  });*/
}

// window.onerror = function (msg, url, line, col, error) {
//   logError(msg, url, line, col, error);
// };

