<template>
  <div
    class="desktop__header"
    :class="{'desktop__header--dark': desktopTheme === 'dark'}"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav
            class="navbar navbar-expand px-0"
            :class="{'navbar-dark bg-dark': desktopTheme === 'dark', 'navbar-light': desktopTheme === 'light'}"
          >
            <a
              class="navbar-brand"
              href="https://rosfines.ru/"
              rel="nofollow noopener"
            >
              <img
                v-if="desktopTheme === 'dark'"
                src="@/assets/img/rosfines-logo-dark.svg"
              >
              <img
                v-else
                src="@/assets/img/rosfines-logo.svg"
              >
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarLanding"
              aria-controls="navbarLanding"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon" />
            </button>

            <div
              id="navbarLanding"
              class="collapse navbar-collapse"
            >
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://rosfines.ru/about"
                    rel="nofollow noopener"
                  >О проекте</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://rosfines.ru/news"
                    rel="nofollow noopener"
                  >Новости</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://rosfines.ru/good_to_know"
                    rel="nofollow noopener"
                  >Журнал</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://rosfines.ru/faq"
                    rel="nofollow noopener"
                  >Частые вопросы</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://taxes.rosfines.ru/"
                    rel="nofollow noopener"
                  >РосНалоги</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="https://rosfines.ru/contacts"
                    rel="nofollow noopener"
                  >Контакты</a>
                </li>
              </ul>
              <ul
                v-if="['index', 'landing'].indexOf($router.currentRoute.value.name) !== -1"
                class="nav navbar-nav d-none d-lg-block"
              >
                <li class="nav-item">
                  <a
                    class="desktop__header__profile-link nav-link"
                    href="https://rosfines.ru/profile"
                    rel="nofollow noopener"
                  >
                    Профиль
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DesktopHeader",
  props: {
    desktopTheme: {
      type: String,
      default: "light",
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/vars.scss';
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_mixins.scss';

.desktop__header {
  background-color: $color-white;
  color: rgba($color-black, 0.5);
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid rgba($color-white, 0.1);
  .navbar {
    padding: 8px 16px 9px;
  }
  .navbar.bg-dark {
    background-color: transparent !important;
  }

  .nav-link {
    font-size: 14px;
    color: rgba($color-black, 0.5);
    font-weight: 500;
  }

  .navbar-brand {
    margin-right: 48px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }

  &__profile-link {
    text-align: center;
    border-radius: 10px !important;
    background-color: #e9ebed;
    color: rgba($color-black, 0.5);
    font-size: 14px !important;
    padding: .6rem 1.5rem !important;
    margin: 0;
    max-width: 160px;
    font-weight: 500 !important;
    line-height: 1.25;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &--dark {
    background-color: $color-dark;
    color: $color-white;

    .nav-link {
      color: rgba($color-white, 0.5);
      font-weight: 300;
    }

    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link:focus {
      color: $color-white;
    }

    .desktop__header__profile-link {
      color: rgba($color-white, 0.7);
      background-color: rgba($color-white, 0.2);
      font-weight: 400 !important;
    }
  }
}
</style>
