import api from "@rosfines/vue-common/common/api/osagoApi";

export const latinSymbols = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const numberSymbols = "0123456789";
export const cyrillicSymbols = "абвгдеёжзийклмнопрстуфхцчшщъыьэюяАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ";

export function numWord(value, words) {
    value = Math.abs(value) % 100;
    const num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num === 1) return words[0];
    return words[2];
}

export function logError(message) {
  console.error(message);
  if (process.env.NODE_ENV === 'production') {
      //Sentry.captureMessage(message, 'error');
  }
}

export function numberFormat(number, delimiter = ' ') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
}

export function hash(string) {
    let hash = 0, i, chr;

    string = String(string);

    if (string.length === 0) {
        return hash;
    }
    for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

/**
 * @description Иногда при переходе с экрана на экран по кнопке браузера (или устройства) Назад на экране остаётся бэкграунд модального окна
 * Функция удаляет его при вызове
 */
export function clearModalBackground() {
    let bg = window.window.$('.modal-backdrop.fade.show');
    if (bg[0]) {
      window.$(bg[0]).remove();
      window.window.$('body').removeClass('modal-open');
    }
}

/**
 * @description Это удобнее чем Math.random()
 * @param {Number} min
 * @param {Number} max
 * @return Number
 */
export function appRand(min, max) {
    var n = 0, k;
    while (n === 0) n = Math.round(Math.random() * (max - min)) + min;
    k = Math.random();
    if (k > 0.5) {
        if (k > 0.75 && n === max - 1) {
            n++;
        } else if (n === min + 1) {
            n--;
        }
    }
    //Просто перестраховка, хотя при тестировании прекрасно работает
    if (n < min) {
        return min;
    }
    return n;
}

/**
 * @description Проверка, ios у пользователя или нет
 * @return Boolean
 */
export function isIos() {
    let ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('iphone') !== -1 || ua.indexOf('ios') !== -1 || ua.indexOf('ipad') !== -1;
}

/**
 * @description Запрос с бека списка id компаний и сохранение его в localStorage
 */
export function loadCompanyIdList() {
    // Если за текущи час уже есть список id компаний, не мучаем сервер бесполезными запросами
    let companiesData = localStorage.getItem('companyIdListData'),
        d = new Date(),
        now = parseInt(d.getTime() / 1000);
    try {
        companiesData = JSON.parse(companiesData);
    } catch (e) {
        companiesData = false;
    }

    if (!companiesData) {
        companiesData = {
            list: [],
            timestamp: 0
        };
    }

    if (now - companiesData.timestamp > 3600) {
        api.get('/getInsuranceCompanyIdList').then(response => {
            if (response.data.status === 'ok') {
                companiesData.timestamp = now;
                companiesData.list = response.data.list;
                companiesData = JSON.stringify(companiesData);
                localStorage.setItem('companyIdListData', companiesData);
            }
        }).catch(() => {
        });

    }
}

export function isEmptyOwner(policyRequestData) {
    if (!('owner' in policyRequestData)) {
        return true;
    }

    return !!Object.keys(policyRequestData.owner).filter((key) => key !== "type").every((k) => {
        return !policyRequestData.owner[k] || !policyRequestData.owner[k].length;
    });
}

/**
 * Сравниваем объекты без тяжелого алгоритма рекурсии
 * @param  {Object} obj Object compared
 * @param  {Object} base   Object to compare with
 * @return {Boolean}
 */
export function compareObjects(obj, base) {
    // Сравниваем только объекты
    if (!(obj instanceof Object)) {

        return false;
    }
    if (!(base instanceof Object)) {

        return false;
    }
    // Чтобы порядок полей в объектах был всегда одинаковым, распарсим их и снова соберем
    // (Браузеры упорядочивают поля в объектах при этом)

    obj = JSON.parse(JSON.stringify(obj));
    base = JSON.parse(JSON.stringify(base));

    obj = JSON.stringify(obj);
    base = JSON.stringify(base);
    return (obj === base);
}

/**
 *
 * @return Boolean true если vehiclePlate валидный
*/
export function isValidVehiclePlate(vehiclePlate) {
    return /^([ЕТУОРАНКХСВМ]{1}[0-9]{3}[ЕТУОРАНКХСВМ]{2}[0-9]{2,3})$/i
        .test(vehiclePlate);
}
