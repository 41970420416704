let modals = {};

export default {
  install: (app) => {
    app.config.globalProperties.$showModal = (key) => {
      modals[key] && modals[key].show();
    };
    app.config.globalProperties.$hideModal = (key) => {
      modals[key] && modals[key].hide();
    };
    app.config.globalProperties.$registerModal = (key, ref) => {
      modals[key] = ref;
    };
    app.config.globalProperties.$unregisterModal = (key) => {
      delete modals[key];
    };
    window.$showModal = app.config.globalProperties.$showModal;
    window.$hideModal = app.config.globalProperties.$hideModal;
  },
}
