<template>
  <header class="app-header">
    <div class="app-header__title">
      <h1>Страхование ОСАГО</h1>
    </div>
    <div>
      <div
        class="app-header__back"
        @click="gotoMain"
      >
        <img
          src="@/assets/img/icons/back.svg"
          alt="X"
        >
      </div>
    </div>
  </header>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: "AppHeader",
  computed: {
    ...mapState({
      showAppHeaderState: state => state.app.showAppHeader,
    }),
  },
  mounted() {
    document.body.classList.add("has-app-header");
  },
  beforeUnmount() {
    document.body.classList.remove("has-app-header");
  },
  methods: {
    gotoMain() {
      switch (this.showAppHeaderState) {
        case 2:
          location.href = "rosfines://action/close";
          // fallback
          setTimeout(() => {
            location.href = "//insurance.rosfines.ru/";
          }, 3000);
          break;
        default:
          location.href = "//insurance.rosfines.ru/";
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars";

.app-header {
  display: flex;
  justify-content: space-between;
  height: 56px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 17px 16px;
  background: $color-white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  z-index: 1500;
  &__title {
    display: flex;
    justify-content: flex-start;
  }
  &__back {
    margin: -1px 0 0;
  }
  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0;
  }
}
</style>
