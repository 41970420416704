<template>
  <div
    class="desktop__footer"
    :class="{'desktop__footer--dark': desktopTheme === 'dark'}"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div>
              <a
                class="navbar-brand"
                href="https://rosfines.ru/"
                rel="nofollow noopener"
              >
                <img
                  v-if="desktopTheme === 'dark'"
                  src="@/assets/img/rosfines-logo-dark.svg"
                >
                <img
                  v-else
                  src="@/assets/img/rosfines-logo.svg"
                >
              </a>
              <div class="desktop__footer__copyright">
                2015–{{ currentYear }} © ООО «КАРБОКС»
              </div>
            </div>
            <div>
              <nav class="nav">
                <a
                  class="nav-link"
                  href="https://rosfines.ru/about"
                  rel="nofollow noopener"
                >О проекте</a>
                <a
                  class="nav-link"
                  href="https://rosfines.ru/news"
                  rel="nofollow noopener"
                >Новости</a>
                <a
                  class="nav-link"
                  href="https://rosfines.ru/good_to_know"
                  rel="nofollow noopener"
                >Журнал</a>
                <a
                  class="nav-link"
                  href="https://rosfines.ru/faq"
                  rel="nofollow noopener"
                >Частые вопросы</a>
                <a
                  class="nav-link"
                  href="https://taxes.rosfines.ru/"
                  rel="nofollow noopener"
                >РосНалоги</a>
                <a
                  class="nav-link"
                  href="https://rosfines.ru/contacts"
                  rel="nofollow noopener"
                >Контакты</a>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="desktop__footer__subfooter d-flex justify-content-between">
            <div class="d-flex align-items-end">
              <a class="navbar-brand" href="https://vk.com/rosfines" rel="nofollow noopener">
                <img v-if="desktopTheme === 'dark'" src="@/assets/img/icons/vk-dark.svg">
                <img v-else src="@/assets/img/icons/vk.svg">
              </a>
            </div>
            <div class="d-flex align-items-end">
              <div>
                <p>
                  <a
                    href="https://rosfines.ru/politics_personal_data"
                    rel="nofollow noopener"
                  >
                    <u>Политика оператора в отношении обработки персональных данных</u>
                  </a>
                </p>
                <p>
                  This site is protected by reCAPTCHA and the Google <a
                    target="_blank"
                    href="https://policies.google.com/privacy"
                    title="https://policies.google.com/privacy"
                    data-renderer-mark="true"
                  ><u>Privacy Policy</u></a><br> and <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    title="https://policies.google.com/terms"
                    data-renderer-mark="true"
                  ><u>Terms of Service</u></a> apply
                </p>
              </div>
            </div>
            <div class="desktop__footer__partners"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopFooter",
  props: {
    desktopTheme: {
      type: String,
      default: "light",
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/vars.scss';
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_mixins.scss';

.desktop__footer {
  font-family: 'Roboto', sans-serif;
  background-color: $color-white;
  font-size: 14px;
  line-height: 1.4;
  border-top: 1px solid rgba($color-white, 0.1);
  padding-top: 57px;
  color: $color-gray;

  &__copyright {
    margin-top: 15px;
  }

  &__subfooter {
    padding-top: 13px;
    padding-bottom: 62px;
  }

  &__partners div {
    margin-bottom: 16px;
  }

  a {
    color: $color-gray;
  }

  nav.nav {
    margin-top: 5px;
  }

  &--dark {
    background-color: $color-dark;
    color: $color-font-gray;

    a.nav-link {
      color: $color-font-gray;
      padding: 0.5rem 8px;
      font-weight: 300;

      &:hover, &:focus {
        color: $color-white;
      }
    }
  }
}
</style>
