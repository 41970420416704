<template>
  <div
    v-if="isTestDomain"
    class="test_label"
  >
    T
  </div>
  <app-header
    v-if="showAppHeader"
  />
  <desktop-header class="d-none d-lg-block" />
  <loading
    v-if="loadingState"
    :active="true"
  />
  <router-view />

  <v-p-restore v-if="restoreCarRequest" />
  <app-modal
    id="modal-recaptcha"
    :short-view="true"
  >
    <template #modalContent>
      <div id="recaptcha-wrapper" style="{height: fit-content}" />
    </template>
  </app-modal>
  <desktop-footer class="d-none d-lg-block" />
</template>
<script>

import finesApi from '@rosfines/vue-common/common/api/finesApi';

window.$ = window.jQuery = require('jquery');
import swalHelper from "@/util/helpers/swal.helper";
import { defineAsyncComponent } from 'vue'
import AppHeader from '@/components/layout/AppHeader';
import Loading from "vue-loading-overlay"
import DesktopHeader from "@/components/blocks/DesktopHeader";
import DesktopFooter from "@/components/blocks/DesktopFooter";
const VPRestore = defineAsyncComponent(() => import('@/components/modals/VPRestore'));
import {autoresizeListener, hideErrorListener, scrollToErrorListener} from "@/services/field-validation.helper";
import urlHelper from "@/util/helpers/url.helper";
import {mapState, mapActions, mapMutations} from "vuex";
import store from "@/store";
import communications from "@rosfines/vue-common/vue/store/communications";
import queryParameters from "@/util/helpers/queryParameters";

queryParameters.run();

localStorage.removeItem('swal-initiation')
setInterval(() => {
  localStorage.removeItem('swal-initiation')
}, 10000);

export default {
  name: 'App',
  components: {
    AppHeader,
    DesktopFooter,
    DesktopHeader,
    Loading,
    VPRestore,
  },
  computed: {
    ...mapState({
      carsListLoaded: state => state.cars.carsListLoaded,
      carsList: state => state.cars.carsList,
      restoreCarRequest: state => state.cars.restoreCarRequest,
      activeCarDataLoaded: state => state.form.activeCarDataLoaded,
      activeCarId: state => state.form.activeCarId,
      isCommonLoading: state => state.app.isCommonLoading,
      showAppHeader: state => state.app.showAppHeader,
    }),
    loadingState() {
      return this.isCommonLoading || !this.carsListLoaded || !this.activeCarDataLoaded;
    },
    isTestDomain() {
      return window.location.host !== 'osago.rosfines.ru' && window.location.host !== 'osago2.rosfines.ru'
    },
  },
  beforeMount() {
    store.dispatch("init");
  },

  async mounted() {
    if (finesApi.isAuth()) {
      hideErrorListener();
      scrollToErrorListener();
      autoresizeListener();
      const queryStringData = urlHelper.parseQueryString();
      const query = queryStringData.GET;
      const hasVP = query.vp && String(query.vp).length > 0;
      const hasCarId = query.carId && String(query.carId).length > 0;
      if (hasVP) {
        await this.loadCarsInfo({ loadActiveCar: false }).catch((e) => swalHelper.showErrorBox(e));
        this.resetActiveCarId();
        await this.chooseCarByVP({ vehiclePlate: decodeURIComponent(query.vp), isCorp: query.isCorp ? (decodeURIComponent(query.isCorp) === 'true') : false  }).catch(async (e) => {
          if (e?.data?.invalidTokenV2) {
            await swalHelper.showErrorBox()
          }
          location.replace("/start");
        });
      } else if (hasCarId) {
        await this.chooseCarById({ carId: decodeURIComponent(query.carId) });
        await this.loadCarsInfo({ loadActiveCar: false }).catch((e) => swalHelper.showErrorBox(e));
      } else {
        await this.loadCarsInfo({}).catch((e) => swalHelper.showErrorBox(e));
        store.commit("form/restoreActiveCarIdIfMissing");
      }
      this.getFeatures();
    }

    store.registerModule('communications', communications);

    await store.dispatch("communications/sendLoadedEvent");

    store.unregisterModule('communications');

    this.checkVersion();
    setInterval(() => this.checkVersion(), 60 * 1000);
  },
  methods: {
    ...mapActions({
      loadCarsInfo: "cars/loadCarsInfo",
      chooseCarByVP: "cars/chooseCarByVP",
      chooseCarById: "cars/chooseCarById",
      getFeatures: "GBFeatures/getFeatures",
    }),
    ...mapMutations({
      resetActiveCarId: "form/resetActiveCarId"
    }),
    async checkVersion() {
      const version = await fetch("/ver.json?" + new Date().getTime());
      if (version && version.status === 200 && version.json) {
        version.json().then(data => {
          if (data && data.version && data.version !== 5) {
            location.reload();
          }
        });
      }
    }
  },
}
</script>

<style lang="scss">
#recaptcha-wrapper > div {
  height: unset !important;
}
</style>
