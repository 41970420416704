/**
 * @param {HtmlElement} el Элемент, имеющий скролл, он будет ресайзен
 * @param {Object} callbackObject {context, method} если передан, будет вызван context.method(height) после изменения размера el
 *
 */
export function autoresize(el, callbackObject) {
  setTimeout(() => {
    const height = Math.max(el.scrollHeight, 52);
    el.style.cssText = 'height: auto !important;';
    el.style.cssText = 'height:' + height + 'px !important;';

    if (callbackObject && callbackObject.context && callbackObject.method && (callbackObject.method instanceof Function) && parseInt(height)) {
      callbackObject.method.call(callbackObject.context, height);
    }
  }, 0);
}

export function checkRequiredInputs() {
  let valid = true;

  window.$('[vue-required^="true"]').each((index, el) => {
    const $el = window.$(el);
    const $elError = $el.attr('vue-required-real-id') ? window.$('#' + $el.attr('vue-required-real-id')) : $el;
    const $errorMessage = $el.attr('vue-required-message') ? $el.attr('vue-required-message') : 'Поле является обязательным к заполнению';
    if (!$elError.length) {
      throw new Error('Element not found!');
    }

    if (!$el.val()) {
      if ($elError.attr('readonly') === 'readonly' || $elError.attr('disabled') === 'disabled') {
        return false;
      }

      addError($elError, $errorMessage);

      valid = false;
    }
  });

  return valid;
}

export function addError($el, message) {
  removeError($el);
  if (!$el.hasClass('is-invalid--vue')) {
    $el.addClass('is-invalid--vue');

    let $parent;
    const $datePicker = $el.closest('.vdp-datepicker');
    if ($datePicker.length) {
      $parent = $datePicker.parent();
      $parent.append(
          `<div class="invalid-feedback--vue d-block js_invalid_feedback">${message}</div>`
      );
    } else {
      $parent = $el.closest('.fly-label').parent();
      $parent.append(
          `<div class="invalid-feedback--vue js_invalid_feedback">${message}</div>`
      );
    }
  }
}

export function scrollToError() {
  const $toScroll = window.$('.js_invalid_feedback').first();
  if ($toScroll.length && $toScroll.offset()) {
    window.$('html, body').animate({
      scrollTop: $toScroll.offset().top - 120
    }, 0);
  }
}

export function removeError($el, isDatePicker = false) {
  $el.removeClass('is-invalid--vue');
  $el.closest('.fly-label').parent().find('.invalid-feedback--vue').remove();

  const $datePicker = $el.closest('.vdp-datepicker');
  if ($datePicker.length && isDatePicker) {
    $datePicker.parent().find('.invalid-feedback--vue').remove();
  }
}

export function hideErrorListener() {
  window.$('body').on('input', '.is-invalid', function () {
    const $el = window.$(this);
    removeError($el);
  });
}

export function scrollToErrorListener() {
  // Скролл к инпуту с ошибкой, в случаи нажатия на кнопку отправки формы
  window.$('body').on('click', 'button[type="submit"]', function () {
    setTimeout(() => {
      scrollToError();
    }, 200);
  });
}

export function autoresizeListener() {
  const $body = window.$('body');
  $body.on('typeahead:selected', 'textarea[vue-autoresize]', function () {
    autoresize(this);
  });
  $body.on('keydown', 'textarea[vue-autoresize]', function () {
    autoresize(this);
  });
  $body.on('focus', 'textarea[vue-autoresize]', function () {
    //autoresize(this);
    window.scrollTo(0, document.body.scrollHeight);
  });
}
